<template>
  <span class="icon d-icon" :class="[size, position, 'icon-'+ icon, extraclass]" v-html="svg">
  </span>
</template>

<script>
// import axios from 'axios';
// import wrapper from 'axios-cache-plugin'

export default {
  data() {
    return {
      svg: "",
    }
  },
  props: {
    icon:{
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'is-small',
    },
    position:{
      type: String,
      default: '',
    },
    extraclass:{
      type: String,
      default: "",
    }
  },
  computed: {
    url(){
      return `/assets/icons/${this.icon}.svg`
    }
  },
  watch: {
    icon(){
      this.FetchIcon()  
    }
  },
  methods: {
    async FetchIcon(){
      try{
        const response = await fetch(this.url)
        if(response.status== 200){
          this.svg = await response.text(); 
        }
      }catch(e){
        console.error("Error descargando svg", this.icon)
      }
    }
  },  
  async mounted(){
    this.FetchIcon()
  }
}
</script>